import _ from 'lodash'

function isBlank(str) {
    return !str || /^\s*$/.test(str)
}

const checkTrainSearch = (q) => {
    if (q.train) return q.train
    else if (q.line) return q.line
    else if (q.station) return q.station
    else if (q.has_train || q.has_train === 'true') return true
    else return false
}

const checkLocationSearch = (q) => {
    return q.location || false
}

const fetchPromotion = async (store, q, location) => {
    const locationSearch = checkLocationSearch(q)
    if (locationSearch) {
        let loadDefault = false
        try {
            await store.dispatch('collection/load', location)
        } catch (err) {
            loadDefault = true
        }
        if (loadDefault) {
            try {
                await store.dispatch('collection/load', 'promotions')
            } catch (err) {}
        }
    }
    // const totalResult = await store.dispatch('deals/search', q)
    // return totalResult
}

const fetchLocation = async (store, q, location) => {
    const locationSearch = checkLocationSearch(q)
    if (locationSearch) {
        try {
            return await store.dispatch('locations/get', location)
        } catch (err) {
            return null
        }
    }
    return null
}

const buildSEO = async (store, route, obj) => {
    // build seo
    const setting = store.state.map
    const article = obj || {}

    const seo = _.cloneDeep(store.state.seo)

    seo.url = `${setting.url}${route.fullPath}`
    seo.og_url = seo.url
    seo.twitter_url = seo.url

    seo.title =
        (isBlank(article.name) ? setting.title : article.name) + ' | Propdeal'

    seo.description = isBlank(article.meta_description)
        ? setting.description
        : article.meta_description
    seo.og_description = seo.description
    seo.twitter_description = seo.description
    seo.keywords = isBlank(article.meta_keyword)
        ? setting.meta_keyword
        : article.meta_keyword + ' ' + (setting.meta_keyword || '')
    seo.og_title =
        (isBlank(article.name) ? seo.title : article.name) + ' | Propdeal'
    seo.twitter_title = seo.og_title

    seo.og_image = isBlank(article.feature_image)
        ? setting.og_image
        : article.feature_image
    seo.twitter_image = isBlank(article.feature_image)
        ? setting.twitter_image
        : article.feature_image

    await store.commit('SET_SEO', seo)
}

const trainLabel = {
    BTS: 'BTS',
    MRT: 'MRT',
    ARL: 'Airport Rail Link',
}

const trainFilterLabel = (q) => {
    const isTrainSearch = checkTrainSearch(q)
    if (!isTrainSearch) {
        return 'สถานีรถไฟฟ้าทั้งหมด'
    } else if (q.has_train) {
        return 'สถานีรถไฟฟ้าทั้งหมด'
    } else if (q.train) return 'รถไฟฟ้า ' + trainLabel[isTrainSearch]
    else if (q.line) return 'รถไฟฟ้า ' + isTrainSearch
    else if (q.station) return 'รถไฟฟ้าสถานี ' + isTrainSearch
    else return 'สถานีรถไฟฟ้าทั้งหมด'
}

const getActiveTrainIndex = (q) => {
    if (q.train === 'BTS') return 0
    if (q.train === 'ARL') return 1
    if (q.train === 'MRT') return 2
    return 0
}

const getTrainLabel = (q) => {
    if (trainLabel[q] !== undefined) {
        return trainLabel[q]
    } else {
        return ''
    }
}

export {
    checkTrainSearch,
    checkLocationSearch,
    fetchPromotion,
    trainFilterLabel,
    getActiveTrainIndex,
    fetchLocation,
    buildSEO,
    getTrainLabel,
}
