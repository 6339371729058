import { computed, useContext, ref } from '@nuxtjs/composition-api'
import _ from 'lodash'

export default function useDevelopers(emit) {
    const { store, app } = useContext()
    const listDevelopers = computed(() => store.getters['developers/featured'])

    const developers = computed(() => {
        return _.map(listDevelopers.value, (item) => ({
            image: item.feature_image,
            title: item.name,
            url: item.slug,
        }))
    })

    const searchByDeveloper = (index) => {
        const developer = developers.value[index]
        app.router.push({
            path: '/search',
            query: { developer: developer.title },
        })
        emit('close')
        emit('closed')
    }

    const queryDeveloperResult = ref([])

    const queryDeveloper = async (keyword) => {
        if (keyword !== '') {
            const results = await store.dispatch('developers/list', {
                name: keyword,
            })

            queryDeveloperResult.value = [
                {
                    data: _.map(results, (item) => ({
                        image: item.feature_image,
                        title: item.name,
                        url: item.slug,
                    })),
                },
            ]
        }
    }

    return {
        developers,
        searchByDeveloper,
        queryDeveloper,
        queryDeveloperResult,
    }
}
