import { computed, useContext, ref, reactive } from '@nuxtjs/composition-api'

// eslint-disable-next-line no-unused-vars
import _ from 'lodash'

import {
    propertyOptions,
    statusOptions,
    orderOptions,
    distanceOptions,
} from './options'

export default function useSearchable(emit) {
    const btsDistance = ref(null)
    const propertyTypeSelect = ref('')
    const projectStatus = ref('')
    const orderByPrice = ref('')

    const clearSearchFilter = () => {
        btsDistance.value = null
        projectStatus.value = ''
        orderByPrice.value = ''
        propertyTypeSelect.value = ''
    }

    const applyFilter = () => {
        emit('apply-filter', {
            btsDistance,
            projectStatus,
            orderByPrice,
            propertyTypeSelect,
        })
        emit('close')
    }

    const { store, app, route } = useContext()

    const currentSearch = computed(() => store.getters.search)

    const currentSearchOption = computed(() => ({
        q:
            currentSearch.value.deal ||
            currentSearch.value.q ||
            // currentSearch.value.location ||
            // currentSearch.value.station ||
            '',
        // location: currentSearch.value.location,
        // station: currentSearch.value.station,
        developer: currentSearch.value.developer,
        brand: currentSearch.value.brand,
        category: currentSearch.value.category,
        price: {
            min: currentSearch.value.price
                ? currentSearch.value.price.split('-')[0]
                : 0.5,
            max: currentSearch.value.price
                ? currentSearch.value.price.split('-')[1]
                : 50,
        },
        train: currentSearch.value.train,
        has_train: currentSearch.value.has_train,
        line: currentSearch.value.line,
    }))

    const sectionConfigs = {
        deals: {
            limit: 3,
            label: 'โครงการ',
            onSelected: (selected) => {
                emit('suggestion-selected', selected.item)
            },
        },
        locations: {
            limit: 3,
            label: 'ทำเล',
            onSelected: (selected) => {
                emit('suggestion-selected', selected.item)
            },
        },
        stations: {
            limit: 3,
            label: 'รถไฟฟ้า',
            onSelected: (selected) => {
                emit('suggestion-selected', selected.item)
            },
        },
    }

    const renderSuggestion = (suggestion) => {
        if (suggestion.item.image) {
            const image = suggestion.item.image
            return (
                <div>
                    <img class="thumbnail" src={image} />
                    {suggestion.item.title}
                </div>
            )
        } else {
            return suggestion.item.title
        }
    }

    const getSuggestionValue = (suggestion) => {
        const { item } = suggestion
        return item.title
    }

    const doSearch = async (event, path = '/deals') => {
        const query = _.cloneDeep(currentSearch.value)
        query.brand = ''
        query.q = query.deal || query.q
        // if (query.location || query.station || query.train || query.category) {
        //     query.q = ''
        // }

        const params = _.pickBy(query, _.identity)

        // params.limit = 8

        const currentPath = route.value ? route.value.path : ''

        // eslint-disable-next-line no-console
        await updateSearchOption(null, null, 'brand', '')

        if (currentPath === path) {
            app.router.replace({
                path: path || '/deals',
                query: _.assign(_.omit(params, 'count', 'deal'), {
                    t: new Date().getTime(),
                }),
            })
        } else {
            app.router.push({
                path: path || '/deals',
                query: _.assign(_.omit(params, 'count', 'deal'), {
                    t: new Date().getTime(),
                }),
            })
        }
    }

    const updateSearchOption = async (
        query,
        count = 0,
        key = '',
        value,
        slug,
        clearKeyword = false
    ) => {
        let payload = {}
        if (key === '') {
            payload = {
                q: clearKeyword ? '' : query.q || '',
                deal: query.deal || '',
                location: query.location || '',
                developer: query.developer || '',
                brand: query.brand || '',
                station: query.station || '',
                price: query.price || '',
                order: query.order || '',
                status: query.status || '',
                bts_distance: query.bts_distance || '',
                category: query.category || '',
                train: query.train || '',
                has_train: query.has_train || '',
                line: query.line || '',
                page: query.page || '',
                count,
            }
        } else {
            const obj = _.cloneDeep(currentSearch.value)
            if (key === 'deal' || key === 'location' || key === 'station') {
                obj.deal = ''
                obj.location = ''
                obj.station = ''
                obj[key] = value
            } else {
                obj[key] = value
            }

            if (clearKeyword) {
                obj.q = ''
            }

            payload = obj
        }
        await store.commit('SET_SEARCH', payload)

        if (key === 'deal' && slug) {
            // doSearch('/deals')
            app.router.push({ path: '/deals/' + slug })
        } else if (key === 'deal') {
            doSearch('/deals')
        } else if (
            key === 'developer' // ||
            // key === 'category'
            // key === 'station' ||
            // key === 'location' ||
            // key === 'train'
        ) {
            if (value !== '') doSearch('/deals')
        }
    }

    const querySuggestionResult = ref([])

    const querySuggestion = async (keyword) => {
        if (keyword !== '') {
            await updateSearchOption(null, null, 'q', keyword)
            await updateSearchOption(null, null, 'station', '')
            await updateSearchOption(null, null, 'location', '')

            const results = await store.dispatch('suggestion/search', keyword)

            const mapping = []

            _.forEach(_.keys(results), (key) => {
                const section = results[key]
                mapping.push({
                    name: key,
                    data: _.map(section, (item) => ({
                        image: item.feature_image,
                        title: item.station_name || item.name,
                        display_title:
                            item.name +
                            (item.name_en ? `/${item.name_en}` : ''),
                        url: item.slug,
                        key,
                    })),
                })
            })
            querySuggestionResult.value = mapping
        } else {
            await updateSearchOption(null, null, 'q', '')
            await updateSearchOption(null, null, 'location', '')
            await updateSearchOption(null, null, 'station', '')
        }
    }

    const propertyType = reactive(propertyOptions)

    return {
        currentSearch,
        sectionConfigs,
        btsDistance,
        propertyTypeSelect,
        distanceOptions,
        projectStatus,
        statusOptions,
        orderByPrice,
        orderOptions,
        clearSearchFilter,
        applyFilter,
        updateSearchOption,
        currentSearchOption,
        renderSuggestion,
        getSuggestionValue,
        querySuggestion,
        querySuggestionResult,
        propertyType,
        doSearch,
        propertyOptions,
    }
}
