import _ from 'lodash'
import { makeGetters } from './helpers/default.helper'

const apiUrl = '/v1/cms/deals'

const defaultState = () => ({
    result: [],
    deal: {},
    pagination: {},
    train: null,
})

const mutations = {
    SET_RESULT(state, list) {
        state.result = list
    },
    SET_DEAL(state, deal) {
        state.deal = deal
    },
    SET_PAGINATION(state, page) {
        state.pagination = page
    },
    SET_TRAIN(state, train) {
        state.train = train
    },
}

const actions = {
    async search({ commit, store, dispatch }, payload) {
        const params = _.assign(_.omit(payload, 'count', 't'), { limit: 8 })
        if (params.category === ' ') {
            params.category = ''
        }

        if (params.sort) {
            //
        } else if (
            params.line ||
            params.station ||
            params.train ||
            params.has_train
        ) {
            params.sort = 'distance_from_bts:asc'
        } else {
            params.sort = 'price:asc'
        }

        const res = await this.$axios.$get(apiUrl + '/search', {
            params,
        })
        await commit('SET_PAGINATION', {
            total: res.total,
            page: res.page,
            limit: res.limit,
            pageCount: res.pageCount,
            hasNextPage: res.hasNextPage,
            hasPrevPage: res.hasPrevPage,
        })
        await commit('SET_RESULT', res.data)
        if (res.train) {
            await commit('SET_TRAIN', res.train)
        } else {
            await commit('SET_TRAIN', null)
        }
        await dispatch(
            'setSearch',
            _.merge(_.omit(payload, 'page'), { count: res.total }),
            {
                root: true,
            }
        )
        return res.data
    },
    async getDeal({ commit }, slug) {
        const res = await this.$axios.$get(apiUrl + '/' + slug)
        await commit('SET_DEAL', res.data)
        return res.data
    },
    async sendLeadForm({ commit }, data) {
        const res = await this.$axios.$post('/v1/cms/leads', data)
        return res.data
    },
}

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    actions,
    getters: makeGetters(defaultState),
}
