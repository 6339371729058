import { makeGetters } from './helpers/default.helper'

const apiUrl = '/v1/cms/trains'

const defaultState = () => ({
    lines: [],
    line: {},
    pagination: {},
})

const mutations = {
    SET_LINES(state, list) {
        state.lines = list
    },
    SET_LINE(state, line) {
        state.line = line
    },
    SET_PAGINATION(state, page) {
        state.pagination = page
    },
}

const actions = {
    async list({ commit, store }, payload) {
        const res = await this.$axios.$get(apiUrl, {
            params: payload,
        })
        await commit('SET_PAGINATION', {
            total: res.total,
            page: res.page,
            limit: res.limit,
            pageCount: res.pageCount,
            hasNextPage: res.hasNextPage,
            hasPrevPage: res.hasPrevPage,
        })
        await commit('SET_LINES', res.data)
        return res.data
    },
}

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    actions,
    getters: makeGetters(defaultState),
}
