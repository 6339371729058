export const MobileFilter = () => import('../../components/MobileFilter.vue' /* webpackChunkName: "components/mobile-filter" */).then(c => wrapFunctional(c.default || c))
export const RangeSlider = () => import('../../components/RangeSlider.vue' /* webpackChunkName: "components/range-slider" */).then(c => wrapFunctional(c.default || c))
export const Suggestion = () => import('../../components/Suggestion.vue' /* webpackChunkName: "components/suggestion" */).then(c => wrapFunctional(c.default || c))
export const TrainList = () => import('../../components/TrainList.vue' /* webpackChunkName: "components/train-list" */).then(c => wrapFunctional(c.default || c))
export const Layout = () => import('../../components/layout.js' /* webpackChunkName: "components/layout" */).then(c => wrapFunctional(c.default || c))
export const ContentBannerBelow = () => import('../../components/content/BannerBelow.vue' /* webpackChunkName: "components/content-banner-below" */).then(c => wrapFunctional(c.default || c))
export const ContentBannerHero = () => import('../../components/content/BannerHero.vue' /* webpackChunkName: "components/content-banner-hero" */).then(c => wrapFunctional(c.default || c))
export const ContentBrandFilter = () => import('../../components/content/BrandFilter.vue' /* webpackChunkName: "components/content-brand-filter" */).then(c => wrapFunctional(c.default || c))
export const ContentBreadcrumb = () => import('../../components/content/Breadcrumb.vue' /* webpackChunkName: "components/content-breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const ContentDealHeroBottom = () => import('../../components/content/DealHeroBottom.vue' /* webpackChunkName: "components/content-deal-hero-bottom" */).then(c => wrapFunctional(c.default || c))
export const ContentDealHeroTop = () => import('../../components/content/DealHeroTop.vue' /* webpackChunkName: "components/content-deal-hero-top" */).then(c => wrapFunctional(c.default || c))
export const ContentDeveloperFilter = () => import('../../components/content/DeveloperFilter.vue' /* webpackChunkName: "components/content-developer-filter" */).then(c => wrapFunctional(c.default || c))
export const ContentGridItem = () => import('../../components/content/GridItem.vue' /* webpackChunkName: "components/content-grid-item" */).then(c => wrapFunctional(c.default || c))
export const ContentLeadBox = () => import('../../components/content/LeadBox.vue' /* webpackChunkName: "components/content-lead-box" */).then(c => wrapFunctional(c.default || c))
export const ContentListItem = () => import('../../components/content/ListItem.vue' /* webpackChunkName: "components/content-list-item" */).then(c => wrapFunctional(c.default || c))
export const ContentLocationFilter = () => import('../../components/content/LocationFilter.vue' /* webpackChunkName: "components/content-location-filter" */).then(c => wrapFunctional(c.default || c))
export const ContentMobileTrainBox = () => import('../../components/content/MobileTrainBox.vue' /* webpackChunkName: "components/content-mobile-train-box" */).then(c => wrapFunctional(c.default || c))
export const ContentPriceBox = () => import('../../components/content/PriceBox.vue' /* webpackChunkName: "components/content-price-box" */).then(c => wrapFunctional(c.default || c))
export const ContentPrivacyBox = () => import('../../components/content/PrivacyBox.vue' /* webpackChunkName: "components/content-privacy-box" */).then(c => wrapFunctional(c.default || c))
export const ContentProjectPlanSwipe = () => import('../../components/content/ProjectPlanSwipe.vue' /* webpackChunkName: "components/content-project-plan-swipe" */).then(c => wrapFunctional(c.default || c))
export const ContentSwiperGrid = () => import('../../components/content/SwiperGrid.vue' /* webpackChunkName: "components/content-swiper-grid" */).then(c => wrapFunctional(c.default || c))
export const ContentSwiperGrid2 = () => import('../../components/content/SwiperGrid2.vue' /* webpackChunkName: "components/content-swiper-grid2" */).then(c => wrapFunctional(c.default || c))
export const ContentSwiperItem = () => import('../../components/content/SwiperItem.vue' /* webpackChunkName: "components/content-swiper-item" */).then(c => wrapFunctional(c.default || c))
export const ContentSwiperItem2 = () => import('../../components/content/SwiperItem2.vue' /* webpackChunkName: "components/content-swiper-item2" */).then(c => wrapFunctional(c.default || c))
export const ContentTrainSearchBox = () => import('../../components/content/TrainSearchBox.vue' /* webpackChunkName: "components/content-train-search-box" */).then(c => wrapFunctional(c.default || c))
export const ContentTransportFilter = () => import('../../components/content/TransportFilter.vue' /* webpackChunkName: "components/content-transport-filter" */).then(c => wrapFunctional(c.default || c))
export const ContentVueTurnstile = () => import('../../components/content/vue-turnstile.vue' /* webpackChunkName: "components/content-vue-turnstile" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/footer/index.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const HeaderMobileMenu = () => import('../../components/header/MobileMenu.vue' /* webpackChunkName: "components/header-mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const HeaderMobileSearch = () => import('../../components/header/MobileSearch.vue' /* webpackChunkName: "components/header-mobile-search" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/header/index.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const HomeHotDeal = () => import('../../components/home/HotDeal.vue' /* webpackChunkName: "components/home-hot-deal" */).then(c => wrapFunctional(c.default || c))
export const Home = () => import('../../components/home/index.js' /* webpackChunkName: "components/home" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
