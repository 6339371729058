import Vue from 'vue'
import Vuesax from 'vuesax'

import { BootstrapVue } from 'bootstrap-vue'

// Install BootstrapVue
Vue.use(BootstrapVue)

if (process.browser) {
    Vue.use(Vuesax, {
        colors: {
            primary: '#f54f59',
            success: 'rgb(23, 201, 100)',
            danger: 'rgb(242, 19, 93)',
            warning: 'rgb(255, 130, 0)',
            dark: 'rgb(36, 33, 69)',
        },
    })
}
