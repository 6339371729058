import { make } from 'vuex-pathify'
import { set, get, cloneDeep } from 'lodash'

/**
 * make default state
 * @param {Object} defaultState
 * @param {Object} states
 * @returns {Object}
 */
export function makeDefaultState(defaultState, ...states) {
    const data = { ...defaultState }
    for (const d of states) {
        Object.assign(data, d)
    }
    return data
}

/**
 * make mutations
 * @param {Object} defaultState
 * @param {Object} mutations
 * @returns {Object}
 */
export function makeMutations(defaultState, ...mutations) {
    const data = { ...make.mutations(defaultState) }
    for (const d of mutations) {
        Object.assign(data, d)
    }

    data.resetState = (state, paths) => {
        if (!paths) {
            Object.assign(state, cloneDeep(defaultState))
            return
        }
        for (const path of paths) {
            set(state, path, cloneDeep(get(defaultState, path)))
        }
    }
    return data
}

/**
 * make getters
 * @param {Object} defaultState
 * @param {Object} getters
 * @returns {Object}
 */
export function makeGetters(defaultState, ...getters) {
    const data = { ...make.getters(defaultState) }
    for (const d of getters) {
        Object.assign(data, d)
    }
    return data
}

/**
 * make Actions
 * @param {Object} defaultState
 * @param {Object} actions
 * @returns {Object}
 */
export function makeActions(defaultState, ...actions) {
    const data = { ...make.actions(defaultState) }
    for (const d of actions) {
        Object.assign(data, d)
    }
    return data
}
