import Vue from 'vue'
// import VueAwesomeSwiper from "vue-awesome-swiper";

// Vue.use(VueAwesomeSwiper);
import {
    Swiper as SwiperClass,
    Pagination,
    Navigation,
    Mousewheel,
    Autoplay,
} from 'swiper/swiper.esm'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'

SwiperClass.use([Pagination, Mousewheel, Navigation, Autoplay])
Vue.use(getAwesomeSwiper(SwiperClass))
// eslint-disable-next-line no-unused-vars
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)
