const propertyOptions = [
    {
        label: 'ทั้งหมด',
        value: ' ',
    },
    {
        label: 'บ้านเดี่ยว',
        value: 'บ้านเดี่ยว',
    },
    {
        label: 'ทาวน์โฮม',
        value: 'ทาวน์โฮม',
    },
    {
        label: 'บ้านแฝด',
        value: 'บ้านแฝด',
    },
    {
        label: 'คอนโดมีเนียม',
        value: 'คอนโดมีเนียม',
    },
]

const distanceOptions = [
    {
        label: 'น้อยกว่า 100 เมตร',
        value: 100,
    },
    {
        label: 'น้อยกว่า 500 เมตร',
        value: 500,
    },
    {
        label: 'น้อยกว่า 1,000 เมตร',
        value: 1000,
    },
    {
        label: 'ทั้งหมด',
        value: 0,
    },
]

const statusOptions = [
    {
        label: 'อยู่ระหว่างก่อสร้าง',
        value: 'building',
    },
    // {
    //    label: 'พร้อมอยู่',
    //    value: 'ready',
    // },
    {
        label: 'ก่อสร้างเสร็จแล้ว',
        value: 'completed',
    },
    {
        label: 'ทั้งหมด',
        value: '',
    },
]

const orderOptions = [
    {
        label: 'ราคาน้อยไปมาก',
        value: 'price:asc',
    },
    {
        label: 'ราคามากไปน้อย',
        value: 'price:desc',
    },
    {
        label: 'เรียงระยะห่างจากรถไฟฟ้า: น้อยไปมาก',
        value: 'distance_from_bts:asc',
    },
    {
        label: 'เรียงระยะห่างจากรถไฟฟ้า: มากไปน้อย',
        value: 'distance_from_bts:desc',
    },
]

export { propertyOptions, statusOptions, orderOptions, distanceOptions }
