import _ from 'lodash'
import { makeGetters } from './helpers/default.helper'

const apiUrl = '/v1/cms/locations'

const defaultState = () => ({
    featured: [],
    locations: [],
    stats: {},
    location: {},
    pagination: {},
})

const mutations = {
    SET_FEATURES(state, list) {
        state.featured = list
    },
    SET_LOCATIONS(state, list) {
        state.locations = list
    },
    SET_STATS(state, list) {
        state.stats = list
    },
    SET_LOCATION(state, brand) {
        state.location = brand
    },
    SET_PAGINATION(state, page) {
        state.pagination = page
    },
}

const actions = {
    async getFeatured({ commit, store }) {
        const res = await this.$axios.$get(apiUrl, {
            params: { featured: 1, limit: 30 },
        })
        await commit('SET_FEATURES', res.data)
        return res.data
    },
    async list({ commit, store }, payload) {
        const res = await this.$axios.$get(apiUrl, {
            params: payload,
        })
        await commit('SET_PAGINATION', {
            total: res.total,
            page: res.page,
            limit: res.limit,
            pageCount: res.pageCount,
            hasNextPage: res.hasNextPage,
            hasPrevPage: res.hasPrevPage,
        })
        await commit('SET_LOCATIONS', res.data)
        return res.data
    },
    async stats({ commit, state }, payload) {
        const res = await this.$axios.$get(apiUrl + '/stats', {
            params: payload,
        })
        const data = _.cloneDeep(state.stats)
        data[payload.category] = res.data
        await commit('SET_STATS', data)
        return res.data
    },
    async get({ commit, store }, payload) {
        const res = await this.$axios.$get(apiUrl + '/' + payload)
        await commit('SET_LOCATION', res.data)
        return res.data
    },
}

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    actions,
    getters: makeGetters(defaultState),
}
