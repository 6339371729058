import { makeGetters } from './helpers/default.helper'

const apiUrl = '/v1/cms/suggestion'

const defaultState = () => ({
    result: [],
    pagination: {},
})

const mutations = {
    SET_RESULT(state, list) {
        state.result = list
    },
    SET_PAGINATION(state, page) {
        state.pagination = page
    },
}

const actions = {
    async search({ commit, store }, payload) {
        const res = await this.$axios.$get(apiUrl, {
            params: { q: payload },
        })
        await commit('SET_PAGINATION', {
            total: res.total,
            page: res.page,
            limit: res.limit,
            pageCount: res.pageCount,
            hasNextPage: res.hasNextPage,
            hasPrevPage: res.hasPrevPage,
        })
        await commit('SET_RESULT', res.data)
        return res.data
    },
}

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    actions,
    getters: makeGetters(defaultState),
}
