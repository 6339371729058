import _ from 'lodash'
import { makeGetters } from './helpers/default.helper'

const defaultSearch = {
    q: '',
    deal: '',
    location: '',
    developer: '',
    brand: '',
    station: '',
    price: '',
    order: '',
    status: '',
    bts_distance: '',
    category: '',
}

const defaultState = () => ({
    settings: [],
    map: {},
    search: defaultSearch,
    seo: {
        title: '',
        description: 'Propdeal รวบรวมดีลเด่น',
        image: 'https://www.propdeal.asia/images/logo.png',
    },
})

const mutations = {
    SET_SETTINGS(state, list) {
        state.settings = list
    },
    SET_SEARCH(state, search) {
        state.search = search
    },
    SET_MAP(state, arr) {
        state.map = arr
    },
    SET_SEO(state, seo) {
        state.seo = seo
    },
}

const actions = {
    async nuxtServerInit({ commit, dispatch, state }, { req }) {
        await dispatch('loadSetting')
        await dispatch('trains/list')
        await dispatch('locations/getFeatured')
        await dispatch('brands/getFeatured')
        await dispatch('developers/getFeatured')

        // commit seo
        const setting = state.map

        await commit('SET_SEO', {
            url: setting.url,
            title: setting.title,
            description: setting.description,
            keywords: setting.meta_keyword,
            og_type: 'website',
            og_url: setting.url,
            og_title: setting.title,
            og_description: setting.description,
            og_image: setting.og_image,
            twitter_card: 'summary_large_image',
            twitter_url: setting.url,
            twitter_title: setting.title,
            twitter_description: setting.description,
            twitter_image: setting.twitter_image,
        })
    },
    async loadSetting({ commit }) {
        try {
            const res = await this.$axios.$get('/v1/cms/settings')

            const map = _.chain(res.data)
                .keyBy('key')
                .mapValues((v) => v.value)
                .value()
            await commit('SET_MAP', map)
            await commit('SET_SETTINGS', res.data)

            return res.data
        } catch (err) {
            return []
        }
    },
    async setSearch({ commit }, payload) {
        const target = _.cloneDeep(defaultSearch, true)
        await commit('SET_SEARCH', _.merge(target, payload), {
            root: true,
        })
    },
}

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    actions,
    getters: makeGetters(defaultState),
}
