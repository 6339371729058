import Vue from 'vue'
import LightBox from 'vue-image-lightbox'
import vClickOutside from 'v-click-outside'
import { VueAutosuggest } from 'vue-autosuggest'

import Suggestion from '../components/Suggestion'

require('vue-image-lightbox/dist/vue-image-lightbox.min.css')

Vue.component('light-box', LightBox)
Vue.use(vClickOutside)
Vue.component('vue-autosuggest', VueAutosuggest)
Vue.component('suggestion', Suggestion)
