import Vue from 'vue'

// import { has } from 'lodash'

import VueLazyload from 'vue-lazyload'
import moment from 'moment'
import VueDOMPurifyHTML from 'vue-dompurify-html'

Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: '/images/error.png',
    loading: '/images/loading.svg',
    attempt: 1,
    lazyComponent: true,
})

Vue.use(require('vue-moment'), {
    moment,
})

Vue.use(VueDOMPurifyHTML)

const eventBus = {}

eventBus.install = function (Vue) {
    Vue.prototype.$bus = new Vue()
}

Vue.use(eventBus)
