import { makeGetters } from './helpers/default.helper'

const apiUrl = '/v1/cms/developers'

const defaultState = () => ({
    featured: [],
    developers: [],
    developer: {},
    pagination: {},
})

const mutations = {
    SET_FEATURES(state, list) {
        state.featured = list
    },
    SET_DEVELOPERS(state, list) {
        state.developers = list
    },
    SET_DEVELOPER(state, developer) {
        state.developer = developer
    },
    SET_PAGINATION(state, page) {
        state.pagination = page
    },
}

const actions = {
    async getFeatured({ commit, store }) {
        const res = await this.$axios.$get(apiUrl, {
            params: { featured: 1, limit: 30 },
        })
        await commit('SET_FEATURES', res.data)
        return res.data
    },
    async list({ commit, store }, payload) {
        const res = await this.$axios.$get(apiUrl, {
            params: payload,
        })
        await commit('SET_PAGINATION', {
            total: res.total,
            page: res.page,
            limit: res.limit,
            pageCount: res.pageCount,
            hasNextPage: res.hasNextPage,
            hasPrevPage: res.hasPrevPage,
        })
        await commit('SET_DEVELOPERS', res.data)
        return res.data
    },
    async get({ commit, store }, payload) {
        const res = await this.$axios.$get(apiUrl + '/' + payload)
        await commit('SET_DEVELOPER', res.data)
        return res.data
    },
}

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    actions,
    getters: makeGetters(defaultState),
}
