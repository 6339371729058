import { computed, useContext, ref } from '@nuxtjs/composition-api'
import _ from 'lodash'

export default function useLocations(emit) {
    const { store, app } = useContext()
    const listLocations = computed(() => store.getters['locations/featured'])
    const locations = computed(() => {
        return _.map(listLocations.value, (item) => ({
            image: item.feature_image,
            title: item.name,
            url: item.slug,
        }))
    })

    const searchByLocation = (index) => {
        const location = locations.value[index]
        app.router.push({
            path: '/deals',
            query: { location: location.title },
        })
        emit('close')
        emit('closed')
    }

    const searchByLocationAndType = (locationName, category) => {
        app.router.push({
            path: '/deals',
            query: { location: locationName, category },
        })
        emit('close')
        emit('closed')
    }

    const queryLocationResult = ref([])

    const queryLocation = async (keyword) => {
        if (keyword !== '') {
            const results = await store.dispatch('locations/list', {
                name: keyword,
                sort: 'name:asc',
            })

            queryLocationResult.value = [
                {
                    name: 'locations',
                    data: _.map(results, (item) => ({
                        image: item.feature_image,
                        title: item.name,
                        display_title: item.name,
                        url: item.slug,
                    })),
                },
            ]
        }
    }

    return {
        locations,
        searchByLocation,
        queryLocation,
        queryLocationResult,
        searchByLocationAndType,
    }
}
