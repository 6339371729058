export default function ({ $axios, error: nuxtError }) {
    // $axios.onRequest((config) => {
    //     console.log(JSON.stringify(config))
    //     // console.log('Making request to ' + config.url)
    // })
    // $axios.onError((error) => {
    //     nuxtError({
    //         statusCode: error.response.status,
    //         message: error.message,
    //     })
    //     return Promise.resolve(false)
    // })
}
