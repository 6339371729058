export default {
    extendRoutes(routes) {
        // Update
        routes.push(
            {
                name: 'คอนโดติดรถไฟฟ้า',
                path: encodeURI('/คอนโดติดรถไฟฟ้า'),
                component: '@/pages/trains/index.vue',
                meta: {
                    category: 'คอนโดมีเนียม',
                },
            },
            {
                name: 'คอนโดติดBTS',
                path: encodeURI('/คอนโดติดBTS'),
                component: '@/pages/deals/index.vue',
                meta: {
                    train: 'BTS',
                    category: 'คอนโดมีเนียม',
                },
            },
            {
                name: 'คอนโดติดMRT',
                path: encodeURI('/คอนโดติดMRT'),
                component: '@/pages/deals/index.vue',
                meta: {
                    train: 'MRT',
                    category: 'คอนโดมีเนียม',
                },
            },
            {
                name: 'คอนโดติดairportlink',
                path: encodeURI('/คอนโดติดairportlink'),
                component: '@/pages/deals/index.vue',
                meta: {
                    train: 'ARL',
                    category: 'คอนโดมีเนียม',
                },
            },
            {
                name: 'คอนโดบางนา',
                path: encodeURI('/คอนโดบางนา'),
                component: '@/pages/deals/index.vue',
                meta: {
                    location: 'บางนา',
                    category: 'คอนโดมีเนียม',
                },
            },
            {
                name: 'คอนโดลาดพร้าว',
                path: encodeURI('/คอนโดลาดพร้าว'),
                component: '@/pages/deals/index.vue',
                meta: {
                    location: 'ลาดพร้าว',
                    category: 'คอนโดมีเนียม',
                },
            },
            {
                name: 'คอนโดพระราม9',
                path: encodeURI('/คอนโดพระราม9'),
                component: '@/pages/deals/index.vue',
                meta: {
                    location: 'พระราม 9',
                    category: 'คอนโดมีเนียม',
                },
            },
            {
                name: 'คอนโดติดรถไฟฟ้าราคาถูก',
                path: encodeURI('/คอนโดติดรถไฟฟ้าราคาถูก'),
                component: '@/pages/deals/index.vue',
                meta: {
                    has_train: 'true',
                    category: 'คอนโดมีเนียม',
                    price: '0.00-3.00',
                    sort: 'price:asc',
                    content: {
                        header: {
                            title: 'รวมดีลเด็ด โปรปัง คอนโดติดรถไฟฟ้าจากแบรนด์ชั้นนำ ราคาดีที่สุด!',
                            description:
                                'แหล่งรวมดีลเด็ดคอนโดราคาถูก คอนโดใกล้รถไฟฟ้าทุกสถานีฮิต ทำเลฮอตในเขตกรุงเทพมหานคร และ ปริมณฑล คัดสรรเฉพาะคอนโดติดรถไฟฟ้าราคาถูก คอนโดราคาไม่เกิน 3 ล้าน รับส่วนลดสุดปัง ประหยัดสูงสุดถึง 60% รวมถึงโปรโมชั่นอัพเดตล่าสุดอื่น ๆ เช่น ฟรี! เฟอร์ เครื่องใช้ไฟฟ้า ส่วนกลางและอื่นๆอีกเพียบ การันตีคุณภาพ คอนโดมือหนึ่ง ส่งตรงจาก Developer เจ้าดังมากมาย ครอบคลุมทั้งรถไฟฟ้า BTS, MRT และ Airport Link ทุกสาย เชื่อมต่อทุกการเดินทางให้คุณสะดวกสบายในราคาเอื้อมถึง',
                        },
                        footer: {
                            title: 'รวมคอนโดติดรถไฟฟ้ากว่า 500 โครงการ',
                            description:
                                'แหล่งรวมโปรโมชั่นคอนโดติดรถไฟฟ้า คอนโดใกล้รถไฟฟ้าราคาถูกจากทุกทำเลในเขตกรุงเทพมหานคร และ ปริมณฑล ดีลเด็ดทุกคอนโด เทียบได้ทุกโครงการ เพราะ Propdeal รวบรวมเอาคอนโดโปรดือ ราคาเด็ดมาไว้ในที่เดียว! อัพเดตล่าสุดทุกโปรโมชั่นเด็ดทุกสัปดาห์ อย่าพลาด! คอนโดราคาไม่เกิน 3 ล้าน มือหนึ่งส่งตรงจาก Developer ในทุกช่วงราคา และทุกทำเล เลือกรถไฟฟ้าที่ต้องการเพื่อค้นหาคอนโดราคาดี โปรเด็ดใกล้สายรถไฟฟ้าที่ตรงใจคุณ คลิกเลย!',
                        },
                        body: {
                            title: 'โปรโมชั่นสุดร้อนแรง คอนโดติดรถไฟฟ้าราคาถูก!',
                            subtitle:
                                'รวมโปรโมชั่นคอนโดราคาดีโปรเด็ดที่ Propdeal คัดสรรมาเพื่อคุณจากทุกทำเลใกล้รถไฟฟ้า',
                        },
                        seo: {
                            title: 'คอนโดราคาถูก! คอนโดใกล้รถไฟฟ้า ราคาไม่เกิน 3 ล้าน โปรเพียบที่ Propdeal',
                            description:
                                'รวมคอนโดใกล้รถไฟฟ้าราคาถูก พร้อมโปรโมชั่นเด็ดสุดคุ้มของคอนโดราคาถูก คอนโดราคาไม่เกิน 3 ล้าน ทั้งฟรีโอน* ฟรีค่าส่วนกลาง* พร้อม Cashback สูงสุด 1 ล้านบาท* และลดแลกแจกแถมอีกมากมาย',
                            keyword:
                                'คอนโดติดรถไฟฟ้า คอนโดใกล้รถไฟฟ้า คอนโดใกล้ BTS คอนโดใกล้MRT คอนโดใกล้แอร์พอร์ตลิงก์ คอนโด BTS คอนโด MRT คอนโดเริ่มไม่เกิน 2 ล้าน',
                        },
                    },
                },
            },
            {
                name: 'search',
                path: '/search',
                component: '@/pages/deals/index.vue',
            }
        )
    },
}
