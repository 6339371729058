import { computed, useContext } from '@nuxtjs/composition-api'
import _ from 'lodash'

export default function useBrands(emit) {
    const { store, app } = useContext()
    const listBrands = computed(() => store.getters['brands/featured'])
    const brands = computed(() => {
        return _.map(listBrands.value, (item) => ({
            image: item.feature_image,
            title: item.name,
            url: item.slug,
            logo: item.logo,
        }))
    })

    const searchByBrand = (index) => {
        const brand = brands.value[index]
        app.router.push({
            path: '/deals',
            query: { brand: brand.title },
        })
        emit('close')
        emit('closed')
    }
    return {
        brands,
        searchByBrand,
    }
}
