import { makeGetters } from './helpers/default.helper'

const apiUrl = '/v1/cms/collections'

const defaultState = () => ({
    collection: {},
})

const mutations = {
    SET_COLLECTION(state, { slug, list }) {
        state.collection[slug] = list
    },
}

const actions = {
    async load({ commit, store }, slug) {
        const res = await this.$axios.$get(`${apiUrl}/slug/${slug}`)

        await commit('SET_COLLECTION', { slug, list: res.data })
        return res.data
    },
}

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    actions,
    getters: makeGetters(defaultState),
}
